import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="unearthed-arcana"></a>Unearthed Arcana</h2>
    <h3><a id="contacts"></a>
CONTACTS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p>
This system codifies a phenomenon commonplace in most long-term campaigns:
the friendly bartender, gruff weaponsmith, or absentminded sage who points
the PCs in the right direction, passes along important clues, or offers
unusual skills and knowledge.
    </p>
    <p>
With this variant, the PCs have one or more unnamed contacts marked on
their character sheets for later use. A player can define a contact for
his character at any point during the game, giving the PC access to a
friendly NPC. This variant is particularly appropriate in campaigns that
feature mysteries, intrigue, and lots of character interaction. It�s
especially effective in the hands of a GM who doesn�t mind improvising new
NPCs on the spur of the moment.
    </p>
    <p>
For example, when a character needs an inscription translated from the
Skoran language, an invitation to the Lord�s Pageant, or the services of
a master in <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (gemcutting), the player tells the GM that he wants to
define one of his character�s contacts for the purpose. Then the GM
describes how the contact came about, from the character�s point of view:
�You buy your lute strings from Dormendo Vedrai, who is the husband of a
Skoran woman, Thiang. She remembers you as one of the musicians who
performed at their wedding feast, and is happy to do you a favor.� In game
terms, Teryeth has a friendly attitude toward the PC that continues unless
the character does something to change the relationship. She is willing to
translate the inscription, and she may perform a similar service on other
occasions as time goes on. The player notes on the character sheet that
one of his character�s contacts has been defined as Thiang Vedrai, a
speaker and writer of the Skoran language.
    </p>
    <b><a id="table-contacts"></a>Table: Contacts</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <thead>
        <tr>
          <th>
Level
          </th>
          <th>
A<sup><sub>1</sub></sup>
          </th>
          <th>
B<sup><sub>2</sub></sup>
          </th>
          <th>
C<sup><sub>3</sub></sup>
          </th>
          <th>
D<sup><sub>4</sub></sup>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colSpan="5">
            <ol>
              <li>Use column A for <a style={{
                  "color": "#579eb6"
                }} href="bard.html">bard</a> levels.
              </li>
              <li>Use column B for <a style={{
                  "color": "#579eb6"
                }} href="cleric.html">cleric</a>, <a style={{
                  "color": "#579eb6"
                }} href="paladin.html">paladin</a>, and <a style={{
                  "color": "#579eb6"
                }} href="rogue.html">rogue</a> levels.
              </li>
              <li>Use column C for <a style={{
                  "color": "#579eb6"
                }} href="fighter.html">fighter</a> and <a style={{
                  "color": "#579eb6"
                }} href="sorcerer.html">sorcerer</a> levels.
              </li>
              <li>Use column D for <a style={{
                  "color": "#579eb6"
                }} href="barbarian.html">barbarian</a>, <a style={{
                  "color": "#579eb6"
                }} href="druid.html">druid</a>, <a style={{
                  "color": "#579eb6"
                }} href="monk.html">monk</a>, <a style={{
                  "color": "#579eb6"
                }} href="ranger.html">ranger</a>, and <a style={{
                  "color": "#579eb6"
                }} href="wizard.html">wizard</a>
levels.
              </li>
            </ol>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr className="odd-row">
          <td>
1st
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
            <p>{`�`}</p>
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
2nd
          </td>
          <td>
1st
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
            <p>{`�`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
3rd
          </td>
          <td>
�
          </td>
          <td>
1st
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
4th
          </td>
          <td>
2nd
          </td>
          <td>
�
          </td>
          <td>
1st
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
5th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
            <p>{`1st`}</p>
          </td>
        </tr>
        <tr>
          <td>
6th
          </td>
          <td>
3rd
          </td>
          <td>
2nd
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
7th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
8th
          </td>
          <td>
4th
          </td>
          <td>
�
          </td>
          <td>
2nd
          </td>
          <td>
            <p>{`�`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
9th
          </td>
          <td>
�
          </td>
          <td>
3rd
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
10th
          </td>
          <td>
5th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
2nd
          </td>
        </tr>
        <tr className="odd-row">
          <td>
11th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
            <p>{`�`}</p>
          </td>
        </tr>
        <tr>
          <td>
12th
          </td>
          <td>
6th
          </td>
          <td>
4th
          </td>
          <td>
3rd
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
13th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
14th
          </td>
          <td>
7th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
            <p>{`�`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
15th
          </td>
          <td>
�
          </td>
          <td>
5th
          </td>
          <td>
�
          </td>
          <td>
3rd
          </td>
        </tr>
        <tr>
          <td>
16th
          </td>
          <td>
8th
          </td>
          <td>
�
          </td>
          <td>
4th
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
17th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
            <p>{`�`}</p>
          </td>
        </tr>
        <tr>
          <td>
18th
          </td>
          <td>
9th
          </td>
          <td>
6th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
19th
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
20th
          </td>
          <td>
10th
          </td>
          <td>
�
          </td>
          <td>
5th
          </td>
          <td>
            <p>{`4th`}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <h5><a id="obtaining-contacts"></a>
OBTAINING CONTACTS
    </h5>
Player characters automatically gain contacts as they rise in level; see
    <a style={{
      "color": "#579eb6"
    }} href="#table-contacts">Table: Contacts</a>. When a PC obtains a new
potential contact, he must select what type of contact it is (<a style={{
      "color": "#579eb6"
    }} href="#information-contacts">information</a>,
    <a style={{
      "color": "#579eb6"
    }} href="#influence-contacts">influence</a>, or <a style={{
      "color": "#579eb6"
    }} href="#skill-contacts">skill</a>), but doesn�t define it further until it�s needed.
    <p>
A multiclass character gains contacts according to his class level in each
of his classes, regardless of what his character level is. For example, a
3rd-level <a style={{
        "color": "#579eb6"
      }} href="bard.html">bard</a>/2nd-level <a style={{
        "color": "#579eb6"
      }} href="barbarian.html">barbarian</a> gains a new contact when he reaches 6th
level if he takes 4th level in bard, but not if he takes 3rd level in
barbarian.
    </p>
    <h5><a id="npcs-and-contacts"></a>
NPCs AND CONTACTS
    </h5>
While all defined contacts are friendly NPCs, that doesn�t mean that all
friendly NPCs are defined contacts. The contact variant is intended to
supplement, not replace, other social interactions with noncombatant NPCs.
It gives a player a chance to insert a minor character into the ongoing
drama.
    <p>
Defined contacts should be among the campaign�s most stable characters.
Unless the characters are completely obtuse or have remarkable misfortune,
the minor characters they define as contacts aren�t going anywhere.
They�re generally available wherever they happen to live, and they usually
have the time and inclination to help their friend the PC. Major NPC
characters &mdash; those defined entirely by the GM &mdash; are off limits as contacts. A
player can�t just say, �I want to define the queen as a contact.�
    </p>
    <p>
A contact won�t risk life or livelihood on the PC�s say-so, but a contact
makes some sacrifices for a friend. For example, a contact will burn the
midnight oil translating an ancient text or sneak the key to the pantry
out of the castle (as long as it�s back by morning).
    </p>
    <p>
There�s an inverse relationship between the contact�s importance in the
ongoing campaign and the amount of help she can provide. In other words,
if you choose the mayor as your contact, sometimes he�s too busy to see
you at a moment�s notice, but he�s very helpful when you get an audience.
Tallin the cobbler, on the other hand, practically lives in his shop on
River Street &mdash; making him available day or night &mdash; but the ways in which he
can aid you are more limited.
    </p>
    <h5><a id="types-of-contacts"></a>
TYPES OF CONTACTS
    </h5>
Contacts come in three varieties: <a style={{
      "color": "#579eb6"
    }} href="#information-contacts">information contacts</a>, <a style={{
      "color": "#579eb6"
    }} href="#influence-contacts">influence
contacts</a>, and <a style={{
      "color": "#579eb6"
    }} href="#skill-contacts">skill contacts</a>.
    <h6><a id="information-contacts"></a>
Information Contacts
    </h6>
Information contacts are useful for what they know. They�re the ones who
hear all the rumors &mdash; and they can discern which ones are true. Some just
have an uncanny sense of what�s going on in their neighborhood or town,
such as the grumpy bartender, the talkative fruit merchant, and the watch
captain who has seen it all. Other information contacts have more focused
interests, such as the army sergeant who knows all about troop movements,
the fence who is privy to every major theft in the city, or the scribe
assigned to write down every utterance of the high cleric-prophets.
    <p>
An information contact is generally a <a style={{
        "color": "#579eb6"
      }} href="npcClasses.html#commoner">commoner</a> or an expert with one-third
the class levels of his PC friend. It�s okay to give such a character a
few levels in another class such as <a style={{
        "color": "#579eb6"
      }} href="wizard.html">wizard</a>, <a style={{
        "color": "#579eb6"
      }} href="rogue.html">rogue</a>, or <a style={{
        "color": "#579eb6"
      }} href="fighter.html">fighter</a> if it�s
reasonable for someone in the contact�s position to have this experience.
Most information contacts spend their skill points on interaction skills
such as <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#diplomacy">Diplomacy</a>, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#gather-information">Gather Information</a>, and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#sense-motive">Sense Motive</a>.
    </p>
    <h6><a id="influence-contacts"></a>
Influence Contacts
    </h6>
Influence contacts are useful because of who they know or who they are
associated with. While a player can�t define the queen as his character�s
contact, he can define one of her chambermaids as a contact. The maid
doesn�t have a broad store of information, and she doesn�t have any skills
the PCs might need. But she might be able to put in a good word with the
queen, and she can certainly make introductions between the PC and the
rest of the queen�s domestic staff. The purpose of an influence contact is
to enable and smooth talks with more important, but less friendly, NPCs.
    <p>
An influence contact has one-quarter the class levels of his PC friend,
almost always in an NPC class (<a style={{
        "color": "#579eb6"
      }} href="npcClasses.html#adept">adept</a>, <a style={{
        "color": "#579eb6"
      }} href="npcClasses.html#aristocrat">aristocrat</a>, <a style={{
        "color": "#579eb6"
      }} href="npcClasses.html#commoner">commoner</a>, <a style={{
        "color": "#579eb6"
      }} href="npcClasses.html#expert">expert</a>, or
      <a style={{
        "color": "#579eb6"
      }} href="npcClasses.html#warrior">warrior</a>) unless the character is in an environment such as a wizard�s
academy where almost everyone has specific class levels.
    </p>
    <h6><a id="skill-contacts"></a>
Skill Contacts
    </h6>
Skill contacts are useful for what they do. Some skills &mdash; especially
categories of <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#craft">Craft</a>, <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#profession">Profession</a>, and <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#knowledge">Knowledge</a> &mdash; are rarely possessed by
PCs. Skill contacts have those skills in abundance, so they�re useful when
characters need a smith to repair a lance, an honest broker to appraise a
giant pearl, or a herald who can identify the helmed knight displaying a
two-headed wyvern on her standard. A special category of the skill contact
is the linguist, who can tell you what �Bree-Yark!� means in Goblin.
    <p>
A skill contact is generally an <a style={{
        "color": "#579eb6"
      }} href="npcClasses.html#expert">expert</a> with half as many levels as his PC
friend. He has maximum ranks in the skill he is best at, and his highest
ability score is in the key ability for the skill in question. A skill
contact always has the <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#skill-focus">Skill Focus</a> feat related to his field of specialty.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      